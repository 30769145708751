import React, { Component } from 'react';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer-3 footer-topbar light page-section-pt">
        <div className="container">
          <div className="row top">
            <div className="col-lg-3 col-md-12">
              <img
                className="img-fluid"
                id="logo_footer_dark"
                src="images/logo.png"
                alt=""
              />
            </div>

            {/* Whatsapp */}
            {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">  */}
            {/*<a  className='whatsappFlutuante' href={'https://api.whatsapp.com/send?text=Olá,vi um anúncio em seu site e gostaria de saber mais sobre o veículo?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link")} target='_blank' >
              <i className="fa fa-whatsapp"></i>
            </a>*/}

            <div className="col-lg-4 col-md-12">
              <div className="social text-lg-right text-center">
                <ul>
                  <li>
                    <a href={this.props.revenda.facebook_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square"></i></a>
                  </li>
                  <li>
                    <a href={this.props.revenda.instagram_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" target="_blank"></i></a>
                  </li>
                  {/*<li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }><i className="fa fa-whatsapp"></i></a>
                  </li>*/}
                  {/* <li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }><i className="fa fa-whatsapp"></i></a>
                  </li> */}
                  <li>
                    <a target="_blank" href={this.props.revenda.mapa_link} className="wow slideInLeft" rel="noopener noreferrer" ><i className="fa fa-map-marker"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="row" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
            <div className="col-lg-5 col-md-12">
              <div className="about-content">
                <h6 className="text-sobrenos">Sobre nós</h6>
                <p className="text-sobrenos-info">
                  A Minas Car Veículos é uma loja especializada na venda de veículos novos e seminovos. Oferecemos uma variedade de carros com qualidade garantida, sempre priorizando a satisfação e segurança dos nossos clientes. Com um atendimento personalizado, nossa equipe está pronta para ajudar você a encontrar o carro ideal, com condições de pagamento facilitadas e ótimas opções de financiamento. Na Minas Car Veículos, confiabilidade e transparência são nossos compromissos!                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <h6 className="text-sobrenos">Informações da loja</h6>
              <div className="usefull-link">
                <div className="row">
                  <div className="col-md-4 footer_info">
                    <ul className="text-sobrenos-info">
                      <li>
                        <a target="_blank" href={this.props.revenda.mapa_link} className="wow slideInLeft" rel="noopener noreferrer" >
                          {this.props.revenda.peendereco_fiscal} - {this.props.revenda.peendereco_fiscal_numero} - {this.props.revenda.pebairro_fiscal} - {this.props.revenda.pecidade} - {this.props.revenda.peuf}
                        </a>
                      </li>
                      {/*<li>
                        <a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >
                          <i className="fa fa-phone"></i>
                          { retiraMascara(this.props.revenda.petelefone1) }
                        </a>
                      </li>*/}
                      {/* <li>
                      <a target="_blank" href="https://linktr.ee/">
                        <i className="fa fa-whatsapp"></i>
                        WHATSAPPs
                        </a>
                      </li> */}
                      {/*<li>
                        <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }>
                          <i className="fa fa-whatsapp"></i>
                          { retiraMascara(this.props.revenda.petelefone2) }
                          </a>
                      </li>*/}
                      <li>
                        <i className="fa fa-envelope-o" style={{ marginRight: "10px" }}> </i>
                        <a href={"mailto:" + this.props.revenda.peemail}>{this.props.revenda.peemail}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p style={{ textAlign: "center" }}>
                    © Copyright 2024 Minas Car  | Todos os direitos reservados.
                    <a href="https://intermedioveiculos.com.br/" target="HKe2fZ02jqcU0ixfKIxz"
                    > Intermédio Veículos Marketing Digital</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;